import { Component, OnInit, OnChanges } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { ParamMap, Router, ActivatedRoute } from '@angular/router';
import { APIGetFactoryService } from '../../../services/get-factory.service';
import { APIPostFactoryService } from '../../../services/post-factory.service';
import { BillingPostFactoryService } from '../../../services/post-factory.service';
import { PassparameterService } from '../../../services/passparameter.service';
import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { GlobalvariableService } from '../../../services/globalvariable.service';
import * as _ from 'lodash';
import * as jwt_decode from 'jwt-decode';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../../../auth/auth.service';
import createApp from '@shopify/app-bridge';
import { Loading } from '@shopify/app-bridge/actions';
import { Redirect } from '@shopify/app-bridge/actions';
var shopifyKeys;
var appBridgeCred={
    appApiKey:"",
    host:""
}

@Component({
    selector: 'app-sh-register',
    templateUrl: './sh-register.component.html',
    styleUrls: ['./sh-register.component.scss']
})
export class ShRegisterComponent implements OnInit {
    // public itemAdded$ = new EventEmitter();
    vm: object;
    valForm: FormGroup;
    passwordForm: FormGroup;
    newUserResponse: any;
    htmlResponse: any;
    regBtnDisabled: boolean;
    checkboxClicked: any;
    isAllDataReceived: boolean = false;
    isEmbedded: any;
    storePhoneAvail: boolean = true;
    appApiKey: any;
    loading: any;
    decodedTokenObj;
    merchantId: string;
    isLivevueActivationCompleted:boolean;
    // toasterconfig: ToasterConfig = new ToasterConfig({
    //     positionClass: 'toast-bottom-right',
    //     showCloseButton: true
    // });

    constructor(@Inject(LOCAL_STORAGE) private storage: StorageService, public settings: SettingsService, fb: FormBuilder, private route: ActivatedRoute, private APIGetFactory: APIGetFactoryService, private APIPostFactory: APIPostFactoryService, public passparameter: PassparameterService, public router: Router, public globalVariable: GlobalvariableService, private snackBar: MatSnackBar, private authService: AuthService, public BillingPostFactory: BillingPostFactoryService) {

        let password = new FormControl('', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9]{6,10}$')]));
        let certainPassword = new FormControl('', [Validators.required, CustomValidators.equalTo(password)]);

        this.passwordForm = fb.group({
            'password': password,
            'confirmPassword': certainPassword
        });

        this.valForm = fb.group({
            'account_org_name': [null, Validators.required],
            'account_phone': [null, Validators.required],
            'account_website': [null, Validators.required],
            'account_email': [null, Validators.required],
            'account_agreed': [false, Validators.requiredTrue],
            'news_subscribed': [false],
            'verify_code': [false, Validators.requiredTrue]
        });

    }
    submitForm($ev, value: any) {
        console.log("this.valForm is", this.valForm);
        console.log("$ev is", $ev);
        console.log("value is", value);
        value.orgName = value.account_org_name;
        value.orgPhone = value.account_phone;
        value.orgWebAddress = value.account_website;
        value.orgEmail = value.account_email;
        value.agreed = value.account_agreed;
        value.verifyCode = value.account_agreed;
        this.register(value, this.valForm)
    }

    redirectForPermission = function (merchantRegisterDetails) {
        // window.location.href = merchantRegisterDetails.auth_url;
        if (window.top === window.self) {
            console.log('window.location.origin if',window.location.origin)
            window.location.href = merchantRegisterDetails.auth_url
        } else {
            console.log('window.location.origin else',window.location.origin)
            var UrlRedirect = merchantRegisterDetails['auth_url']
            const app = createApp({
                apiKey: appBridgeCred.appApiKey,
                host: appBridgeCred.host,
            });
            Redirect.create(app).dispatch(Redirect.Action.REMOTE, UrlRedirect);
        }
    };

    getParams = function (searchUrl) {

        var str = searchUrl;
        var objURL = {};
        console.log('str replace', str);
        if (str) {
            str.replace(
                new RegExp("([^?=&]+)(=([^&]*))?", "g"),
                function ($0, $1, $2, $3) {
                    objURL[$1] = $3;
                }
            );
        }
        console.log('objURL ', objURL);
        return objURL;
    }


    ngOnInit() {
        this.APIGetFactory.customGet("lb4_merchant", "getAppApiKey", { appName: "larfsh" }).subscribe(input => {
            this.appApiKey = input["apiKey"]
            appBridgeCred.appApiKey = input["apiKey"]
            var stopLoadingBar = "";
            // this.vm['stopLoadingBar'] = true;
            // this.vm['shopifyKeys'] = {};
            console.log("in shopify register page");
            this.isEmbedded = this.storage.get('isEmbedded');
            var parentURL
            if (window.location != window.parent.location) {
                parentURL = document.referrer;
            } else {
                parentURL = document.location;
            }
            // var parentURL = (window.location != window.parent.location) ? document.referrer : document.location;
            // var parentURL = window.location;
            console.log('parentURL in shopifyRegisterController : ', parentURL);
            var code, appname;
            var data = {};
            // data.appname = $stateParams.appname;
            var searchParams = {};
            if (!_.isObject(parentURL)) {
                // console.log('input for get parentURL = ', parentURL )
                var newSearchParams = parentURL.split('?');
                searchParams = this.getParams(newSearchParams[1]);
                // console.log('input for get searchParams = ', searchParams )
                if (searchParams['hmac'] == undefined) {
                    searchParams = this.getParams(window.location.search);
                }

            } else {
                // console.log('input for get getParams = ', window.location.search);
                searchParams = this.getParams(window.location.search);
            }
            var queryData = _.extend(data, searchParams);
            if (this.isEmbedded) {
                // console.log('here it is', isEmbedded);
                queryData = JSON.parse(this.isEmbedded);
                this.storage.remove('isEmbedded');
            }
            if (searchParams['charge_id'] !== undefined && !(sessionStorage.getItem('LB4_Contour_Frame'))){
                console.log("App pricing")
                if (sessionStorage.getItem('LB4_service')) {
                    console.log("In ServicePricing")
                    this.BillingPostFactory.customPost('LB4_ServicePricing', 'servicePlanUpdate', queryData).subscribe(merchantData => {
                       console.log('merchantData',merchantData) 
                       if(merchantData[0].status == 'active' && merchantData[0].name == 'LIVEARf Service Pricing'|| merchantData[0].status == 'active' && merchantData[0].name == 'LIVEARf Features onetime Pricing'){
                        this.snackBar.open('LIVEARf Service Pricing Activated', 'dismiss', { duration: 3000 });
                       }
                    })
                  }else if (sessionStorage.getItem('LB4_features_pricing')) {
                    this.BillingPostFactory.customPost('LB4featurespricing', 'featuresPlanUpdate', queryData).subscribe(merchantData => {
                       console.log('merchantData',merchantData) 
                    //    this.router.navigate(['/plan-upgrade/pricing'])
                       if(merchantData[0].status == 'active'){
                        this.snackBar.open('LIVEARf Features Pricing Activated', 'dismiss', { duration: 3000 });
                       }
                    })
                  }else {
                    this.BillingPostFactory.customPost('LB4_Merchant', 'updatePurchaseStatusOnAppLoad', queryData).subscribe(merchantData => {
                        if (merchantData[0].app_purchase_status == 'active') {
                            // toaster.pop('success', 'App Purchase successfully Completed', '');
                            this.snackBar.open('App Purchase successfully Completed', 'dismiss', { duration: 3000 });
                        }
                    })
                  }
                
            }
            this.checkAndGetMerchantInfo(queryData);
        })
        this.decodedTokenObj = this.storage.get('decodedTokenObj');
        this.merchantId = this.decodedTokenObj['merchant']
        this.APIGetFactory.simpleOneGet("LVUE_Demo_Status", { merchant: this.merchantId, status: "Active" }).subscribe(activationData => {
            console.log("data in LVUE_Demo_Status ", activationData)
            if (!_.isEmpty(activationData)) {
                if (activationData[0]['live_vue_status'] == "pricing_created") {
                    this.isLivevueActivationCompleted = true;
                }
            }
    })

    }
    passMerchantValues(merchantInfo) {
        this.storage.set('', {
            newMerchantID: merchantInfo.id,
            storeHash: merchantInfo.store_hash,
            platform: merchantInfo.platform,
            formattedAddress: merchantInfo.address,
            appname: merchantInfo.appname
        })
        // _.extend({}, setValue);
    };
    register(accountData, controllerObj) {
        this.regBtnDisabled = true;
        this.htmlResponse = accountData;
        console.log('submitForm is reached ', accountData);
        console.log('submitForm is reached controllerObj ', controllerObj);
        console.log("controllerObj.valid", controllerObj.valid);
        console.log("controllerObj.status", controllerObj.status);
        console.log("this.storage.get('') is", this.storage.get('unregisteredMerchant'));
        //controllerObj.status = "VALID";
        if (controllerObj.status == 'VALID') {
            console.log('submitForm is valid');
            // this.checkboxClicked['noAccountAgreed'] = false;
            // this.checkboxClicked['noVerifyCode'] = false;
            //accountData.account_agreed = true;
            if (accountData.account_agreed) {
                console.log('accountData.agreed ');
                this.createNewMerchantLogin(accountData, controllerObj, this.newUserResponse, 'app.fixuninstalldata', 'app.activation');
            }
        } else {
            if (!accountData.account_phone || accountData.account_phone == undefined) {
                this.storePhoneAvail = false;
            }
            this.regBtnDisabled = false;
            console.log('Not valid!!', accountData);
            if (!accountData.account_agreed && !accountData.verify_code) {
                // this.checkboxClicked['noAccountAgreed'] = true;
                // this.checkboxClicked['noVerifyCode'] = true;
                this.snackBar.open('Agree for Terms & Allow verification codes to reach your email!', 'dismiss');
            } else if (!accountData.verify_code) {
                // this.checkboxClicked['noVerifyCode'] = true;
                // this.checkboxClicked['noAccountAgreed'] = false;
                this.snackBar.open('Allow verification codes to reach your email!', 'dismiss');
            } else if (!accountData.account_agreed) {
                // this.checkboxClicked['noVerifyCode'] = false;
                // this.checkboxClicked['noAccountAgreed'] = true;
                this.snackBar.open('Agree for Terms', 'dismiss');
            }
        }
    }
    createNewMerchantLogin(merchant, controllerObj, newUserResponse, fixuninstalldata, activation) {
        console.log("in createNewMerchnatLogin ");
        this.htmlResponse = controllerObj;
        // var toaster = this.toaster;
        this.htmlResponse.buttonDisabled = true;
        // LoadingIcon.iconLoadingStart('inLogin');
        var receivedParameters = this.storage.get('');
        var loginData = {};

        merchant.merchantId = receivedParameters['newMerchantID'];
        merchant.formattedAddress = receivedParameters['formattedAddress'];
        merchant.storeHash = loginData['storeHash'] = receivedParameters['storeHash'];
        merchant.appname = loginData['appname'] = receivedParameters['appname'];

        loginData['email'] = merchant.account_email;
        // raj on 15 JAN 16 . Arrangement for not asking email for the Store Owner again and again.
        merchant.perEmail = merchant.account_email;
        loginData['password'] = merchant.password;
        loginData['merchant'] = receivedParameters['newMerchantID'];
        loginData['isAgreed'] = merchant.account_agreed;
        loginData['isSubscribed'] = merchant.news_subscribed;
        loginData['sendVerificationCode'] = merchant.verify_code;

        console.log(" this.htmlResponse.createNewMerchantLogin merchant ", merchant, " loginData ", loginData, 'newUserResponse', newUserResponse);

        this.APIPostFactory.customPost('Auth', 'register', loginData).subscribe(response => {
            console.log('Reponse after uploding auth/register', response);
            this.authService.isLoggedInSuccess = true;
            // Raj - have to review where this aa_user_id is used in subsequent new merchant registration ;
            var userInfo = response['userInfo'];
            var activeMerchants = response['merchants'];

            merchant.aa_user_id = userInfo.id; //response.id;
            var merchantData = {
                merchantData: merchant,
                newUserResponse: newUserResponse
            };
            this.getMerchantData(activeMerchants, merchantData, merchant);
        }, function (error) {
            console.log('In then Error -bigcommerceRegisterController : ', error);
            // LoadingIcon.iconLoadingStop();
            if (error.status === 400 && error.data === "User already exists") {
                this.snackBar.open('Please Contact Admin', 'dismiss');
            }
            else {
                this.snackBar.open('Please Contact Admin', 'dismiss');
            }
        });
    }
    getMerchantData(activeMerchants, merchantData, merchant) {
        console.log("activeMerchants in getMerchantData is", activeMerchants);
        // var toaster = this.toaster;
        var globalStorage = this.storage;
        var navRouter = this.router;
        this.APIPostFactory.customPost('LB4_Merchant', 'createMerchantRegister', merchantData).subscribe(async response => {
            this.authService.isLoggedInSuccess = true;
            // this.regBtnDisabled = false;
            console.log('createMerchantRegister response ', response);


            // LoadingIcon.iconLoadingStop();
            globalStorage.set('', {
                userEmail: merchant.orgEmail
            });
            var jwtToken = response[5].jwt.token;
            localStorage.setItem('jwt', jwtToken);
            var decodedToken = jwt_decode(jwtToken);
            globalStorage.set('decodedTokenObj', decodedToken);
            console.log('The JWT Token Generated is :', jwtToken);
            await this.APIGetFactory.customGet('LARF_Keycloak', 'getKcTokenMerchant', { merchantId: decodedToken.merchant }).subscribe(data => {
                console.log("live-sec data from lb4b-api:", data);
                let kc_tokens = JSON.parse(JSON.stringify(data));
                globalStorage.set('lb4b-token', JSON.stringify(data));
                localStorage.removeItem("jwToken");
                console.log("kc tokens:", kc_tokens);
                setInterval(async () => {
                    var toBackend = {
                        access_token: JSON.parse(this.storage.get('lb4b-token')).access_token,
                        refresh_token: JSON.parse(this.storage.get('lb4b-token')).refresh_token
                    }
                    await this.APIGetFactory.customGet('LARF_Keycloak', 'refreshKcToken', toBackend).subscribe(async data => {
                        console.log("refresj live-sec data from lb4b-api:", data);
                        console.log("before refresh", JSON.parse(globalStorage.get("lb4b-token")))
                        globalStorage.set('lb4b-token', JSON.stringify(data));
                        console.log("After refresh", JSON.parse(globalStorage.get("lb4b-token")))
                    })
                    // 1sec=1*1000 so 1min=60*1000 similarly 30mins=30*60=1800==>1800*1000 secs
                }, 1800 * 1000);  
                if (activeMerchants.length > 1) {
                    var merchantsList = {
                        merchantsList: activeMerchants
                    }
                    globalStorage.set('', merchantsList);
                    // this.globalVariable.merchantsList = merchantsList.merchantsList;
                    navRouter.navigate(['fixuninstall/fixuninstall'])
                    // $state.go(fixuninstalldata);
                } else {
                    console.log('activeMerchants', activeMerchants);
                    navRouter.navigate(['setup/activation'])
                }
             })

            this.snackBar.open('Merchant Registered successfully', 'dismiss');
        }, function (error) {
            // login -- false
            this.snackBar.open('Please Contact Admin', 'dismiss');
        });
    }
    checkAndGetMerchantInfo(queryData) {
        console.log("queryData in checkAndGetMerchantInfo is here here...", queryData);
        var globalStorage = this.storage;
        var tempGlobalVariable = this.globalVariable;
        var navRouter = this.router;
        var globalAuthGuard = this.authService;
        appBridgeCred.host = queryData.host
        sessionStorage.setItem('lb4b-host', queryData.host);
        // console.log("sess", appBridgeCred)
        this.APIGetFactory.customGet('LB4_Merchant', 'checkAndGetMerchantData', queryData).subscribe(async merchantRegisterDetails => {
            console.log("merchantRegisterDetails isisis", merchantRegisterDetails);
            shopifyKeys = merchantRegisterDetails['shopifyKeys'];
            // this.isAllDataReceived = true;
            if (merchantRegisterDetails['isOAuthScopeApprovalRequired'] === true) {
                this.redirectForPermission(merchantRegisterDetails);
            } else if (merchantRegisterDetails['isOAuthScopeApprovalRequired'] === false && !this.isEmbedded) {
                globalStorage.set('isEmbedded', JSON.stringify(queryData));
                window.location.href = merchantRegisterDetails['shopifyKeys'].shopOrigin + '/admin/apps/' + merchantRegisterDetails['shopifyKeys']['apiKey'];//+'?isEmbedded=true';
            } else if (merchantRegisterDetails['isOAuthScopeApprovalRequired'] === false) {
                this.APIGetFactory.customGet('LB4_Merchant', 'registerShopify', queryData).subscribe(dbNewUserResponse => {
                    this.newUserResponse = dbNewUserResponse;
                    this.isAllDataReceived = true;
                    console.log('registerShopify backend Response', dbNewUserResponse);
                    globalStorage.set('dbNewUserResponse', dbNewUserResponse);
                    this.passMerchantValues(dbNewUserResponse)
                    console.log("stop loading start")
                    const app = createApp({
                        apiKey: appBridgeCred.appApiKey,
                        host: appBridgeCred.host,
                    });
                    var loading = Loading.create(app);
                    loading.dispatch(Loading.Action.STOP);
                    console.log("stop loading stop")
                    //if (dbNewUserResponse.data == 'MerchantRegisteredEarlier' && dbNewUserResponse.users.length) {
                    if (dbNewUserResponse['data'] == 'MerchantRegisteredEarlier') {
                        this.authService.isLoggedInSuccess = true;
                        // console.log('user - registed');
                        navRouter.navigate(['setup/activation']);
                    }
                }, function (error) {
                    console.log('handle this error', error);
                    if (error.data && error.data.data == 'MerchantRegisteredEarlier') {
                        this.authService.isLoggedInSuccess = true;
                        navRouter.navigate(['setup/activation']);
                    } else {
                        /*SweetAlert.swal({
                            title: "Web Error",
                            text: "Unknown error occoured, Please close the page and re-open",
                            type: "error",
                            showCancelButton: false,
                            confirmButtonColor: "#DD6B55",
                            confirmButtonText: "Enable",
                            closeOnConfirm: false
                        },
                        function(){
                            console.log('when to close this app');
                        });*/
                    }
                });
            } else if (merchantRegisterDetails['appLoaded'] === true && merchantRegisterDetails['isOAuthScopeApprovalRequired'] === undefined) {
                console.log("else 360")
                globalAuthGuard.isLoggedInSuccess = true;
                // $scope.shopifyKeys = merchantRegisterDetails['shopifyKeys'];
                // while testing without JWT pls commentout following store.set line
                globalStorage.set('jwt', merchantRegisterDetails['token']);
                var decodedToken = jwt_decode(merchantRegisterDetails['token']);
                globalStorage.set('decodedTokenObj', decodedToken);
                await this.APIGetFactory.customGet('LARF_Keycloak', 'getKcTokenMerchant', { merchantId: decodedToken.merchant }).subscribe(data => {
                    console.log("live-sec data from lb4b-api:", data);
                    let kc_tokens = JSON.parse(JSON.stringify(data));
                    globalStorage.set('lb4b-token', JSON.stringify(data));
                    localStorage.removeItem("jwToken");
                    console.log("kc tokens:", kc_tokens);
                    setInterval(async () => {
                        var toBackend = {
                            access_token: JSON.parse(this.storage.get('lb4b-token')).access_token,
                            refresh_token: JSON.parse(this.storage.get('lb4b-token')).refresh_token
                        }
                        await this.APIGetFactory.customGet('LARF_Keycloak', 'refreshKcToken', toBackend).subscribe(data => {
                            console.log("refresj live-sec data from lb4b-api:", data);
                            console.log("before refresh", JSON.parse(globalStorage.get("lb4b-token")))
                            globalStorage.set('lb4b-token', JSON.stringify(data));
                            console.log("After refresh", JSON.parse(globalStorage.get("lb4b-token")))
                        })
                        // 1sec=1*1000 so 1min=60*1000 similarly 30mins=30*60=1800==>1800*1000 secs
                    }, 1800 * 1000);
                    if (merchantRegisterDetails['merchantStoreStatus'] === 'StoreUpdated') {
                        // console.log('LoginFormController merchant store status is StoreUpdated', merchantRegisterDetails.merchantStoreStatus);
                        navRouter.navigate(['livevue-three-point-zero/set-up']);
                        //$state.go('app.singleview');
                        this.snackBar.open('You are successfully logged into LIVEb4buy.', 'dismiss');
                    } else if (sessionStorage.getItem('LB4_Contour_Frame')){
                        console.log("In contourFramePricing")
                        this.authService.isLoggedInSuccess = true;
                        this.router.navigate(['livevue/contour-frames'],{ queryParams: queryData });
                    }else if(this.isLivevueActivationCompleted){
                        navRouter.navigate(['livevue-three-point-zero/set-up']);
                    } 
                    else {
                        // console.log('LoginFormController merchant store status', merchantRegisterDetails.merchantStoreStatus);
                        // navRouter.navigate(['on-boarding/on-boarding-steps']);
                        navRouter.navigate(['livevue-three-point-zero/set-up']);
                    }
                })
                // apploadJwtObj.decodedToken = jwtHelper.decodeToken(merchantRegisterDetails['token']);
                /*$rootScope.$broadcast('jwtgenerated', {
                    jwt: merchantRegisterDetails.token
                });*/
            }
        }, function (error) {
            console.error("Error with status code.....", error);
            if (error.error.data.status == 'merchant registered') {
                this.authService.isLoggedInSuccess = true;
                console.log("stop loading start")
                const app = createApp({
                        apiKey: appBridgeCred.appApiKey,
                        host: appBridgeCred.host,
                    });
                var loading = Loading.create(app);
                loading.dispatch(Loading.Action.STOP);
                console.log("stop loading stop")
                navRouter.navigate(['setup/activation'])
                // $state.go('app.activation');
            } else if (error.error.data.status === 'activate your app first') {
                console.log('LoginFormController merchant store status', error.error.data);
                this.snackBar.open('You are not activated the Account.', 'Activate First, Thanks!.', 'dismiss');
                // var pageNavigate = '?'.concat();
                globalStorage.set('', {
                    userEmail: error.error.data.email
                });
                navRouter.navigate(['emailverify']);
            } else if (error.error.data.status === 'User account created verify for next process') {
                globalStorage.set('', {
                    userEmail: error.error.data.email
                });
                navRouter.navigate(['emailverify']);
            } else if (error.error.data.status === 'Merchant should complete register') {
                tempGlobalVariable.unregisteredMerchant = error.error.merchant;
                console.log("stop loading start")
                const app = createApp({
                        apiKey: appBridgeCred.appApiKey,
                        host: appBridgeCred.host,
                    });
                    var loading = Loading.create(app);
                    loading.dispatch(Loading.Action.STOP);
                console.log("stop loading stop")
                navRouter.navigate(['register']);
            } else if (error.status == 400) {
                console.log("Error Reason", error.error.data.error);
            }
        });
    }
}
